<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item label="选择项目" prop="project_id">
				   <el-select v-model="queryForm.project_id" placeholder="请选择项目" style="width: 230px">
				      <el-option v-for="item in projects" :key="item.id" :value="item.id" :label="item.name" />
				   </el-select>
				</el-form-item>
				<el-form-item label="选择型号" prop="model_id">
				   <el-select v-model="queryForm.model_id" placeholder="请选择型号"style="width: 230px">
				      <el-option v-for="item in models" :key="item.id" :value="item.id" :label="item.model" />
				   </el-select>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="序号" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="头像" min-width="110" align="center">
				  <template slot-scope="scope">
					<img :src="scope.row.url" style="width: 50px;height: 50px;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
					<img src="@/assets/images/empty.png" style="width: 30px;height: 30px;" v-else>
				  </template>
				</el-table-column>
				<el-table-column label="图片名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="项目名称" min-width="110" align="center" prop="project_name"></el-table-column>
				<el-table-column label="型号类型" min-width="110" align="center" prop="model"></el-table-column>
				<el-table-column label="强制更新" min-width="60" align="center">
					<template slot-scope="scope">
						<el-switch
						 v-model="scope.row.refresh"
						 @change="changeStatus(scope.row,'refresh')"
						 active-color="#13ce66"
						 :active-value="1"
						 :inactive-value="2">
						 </el-switch>
					</template>
				</el-table-column>
				<el-table-column label="状态" min-width="70" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">待处理</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="170">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="130" align="center" fixed="right">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="审核" placement="top" v-if="allowEdit">
					  <el-button type="warning" icon="el-icon-setting" size="mini" circle @click="handleOperate(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			<el-form-item label="选择项目" prop="project_id">
			   <el-select v-model="form.project_id" placeholder="请选择项目" class="w-100">
			      <el-option v-for="item in projects" :key="item.id" :value="item.id" :label="item.name" />
			   </el-select>
			</el-form-item>
			<el-form-item label="选择型号" prop="model_id">
			   <el-select v-model="form.model_id" placeholder="请选择型号" class="w-100">
			      <el-option v-for="item in models" :key="item.id" :value="item.id" :label="item.model" />
			   </el-select>
			</el-form-item>
		    <el-form-item label="图片名称" prop="name">
		      <el-input v-model="form.name" placeholder="请输入图片名称" type="text" clearable />
		    </el-form-item>
			<el-form-item label="图片地址" prop="url">
			  <el-upload
				class="upload-border"
				action="/manage/images/upload"
				:show-file-list="false"
				:on-success="uploadSuccess">
				<img v-if="form.url" :src="form.url" class="w-100 h-100">
				<i v-else class="el-icon-plus uploader-icon"></i>
			  </el-upload>
			</el-form-item>
<!-- 		    <el-form-item  label="状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="1">正常</el-radio>
		      	<el-radio :label="2">待处理</el-radio>
		      </el-radio-group>
		    </el-form-item> -->
			<el-form-item label="排序" prop="sort">
			  <el-input v-model="form.sort" placeholder="请输入排序" type="number" clearable />
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
		<!-- 审核-->
		<el-dialog :visible.sync="openDialog" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form2" :model="form2" label-width="80px" size="small">
			<el-form-item label="图片名称" prop="name">
			  <el-input v-model="form2.name" placeholder="请输入图片名称" type="text" clearable disabled/>
			</el-form-item>
		    <el-form-item  label="状态">
			  <el-radio-group v-model="form2.status">
				<el-radio :label="1">已处理</el-radio>
				<el-radio :label="2">待处理</el-radio>
			  </el-radio-group>
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
			<el-button size="mini" @click="openDialog = false">取 消</el-button>
			<el-button type="primary" size="mini" @click="handleOperateSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'upgrade',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					project_id:'',
					model_id:'',
				},
				form:{
					project_id:'',
					model_id:'',
					name:'',
					url:'',
					sort:100,
					status:2,
				},
				rules:{
					project_id: [
					  { required: true, message: '请选择项目', trigger: ['blur', 'change'] }
					],
					model_id: [
					  { required: true, message: '请选择型号', trigger: ['blur', 'change'] }
					],
					name: [
					  { required: true, message: '请选输入图片名称', trigger: 'blur' }
					],
					url: [
					  { required: true, message: '请选选择图片', trigger: 'blur' }
					],
				},
				openDialog:false,
				form2:{
					id:'',
					name:'',
					status:1,
				},
				projects:[],
				models:[],
				userId:this.VueCookies.get('userId'),
			}
		},
		mounted() {
			this.getProjects()
			this.getModels()
		},
		computed:{
			allowEdit(){
				if(this.userId){
					if(this.userId == 100){
						return true
					}
				}
				return false
			}
		},
		methods:{
			reset() {
			  this.form = {
				project_id:'',
				model_id:'',
			    name:'',
				url:'',
			    sort:100,
				status:2,
			  }
			  this.resetForm('form')
			},
			getProjects(){
			  this.axios.get('/manage/project/all').then(res=>{
				this.projects = res.data
			  })
			},
			getModels(){
			  this.axios.get('/manage/model/all').then(res=>{
				this.models = res.data
			  })
			},
			uploadSuccess(e){
			   if(e.status){
				 this.form.url = e.data.url
			   }
			},
			handleOperate(e){
				this.title = '审核'
				this.resetForm('form2')
				this.form2 = {
					id:e.id,
					name:e.name,
					status:e.status,
				}
				this.openDialog = true
			},
			handleOperateSubmit(){
				this.axios.post('/manage/'+this.preUrl+'/operate',this.form2).then(res=>{
					if(res.status){
					  this.openDialog = false
					  this.$message.success('审核成功')
					  this.getList()
					}else{
					  this.$message.error(res.msg)
					}
				})
			},
		}
	}
</script>

<style>
</style>